<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Gestionar Vacunas/Medicamentos por defecto
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="clean();reloadp++; this.allTemplates();" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :key="reloadp">
      <v-card >
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            VACUNAS / MEDICAMENTOS POR DEFECTO 
            <v-icon>mdi-pencil-circle</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">


              <div class="ma-1">
                <v-row  style="margin: .4rem;">
                  
                  <v-col class="tabsNh"  cols="12" >
                    <v-col   cols="12" style="display:flex; justify-content: flex-end;">
                      
                      <v-btn
                        small
                        class="btnclean mr-1"
                        @click.prevent.stop="clean()">
                        <v-icon >
                          mdi-broom
                        </v-icon>
                      </v-btn>
                      
                      <v-btn
                        small
                        
                        class="btnsave mr-1"
                        @click.prevent.stop="saveTemplate()">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col  v-if="detailsparams" cols="12" style="display:flex; justify-content: space-between;">
                      <v-btn
                                          color="error"
                                          small
                                          @click.prevent="templateToDelete = detailsparams;
                                            showAlert('question', 'Eliminar', '¿Seguro que desea eliminar el examen de laboratorio seleccionado?', 'delete');"
                                          >
                                            <v-icon>mdi-delete</v-icon>
                                          </v-btn>
                      <div>
                       
                     
                        <v-btn
                        small
                    class="btnsave"
                    @click.prevent.stop="updatemanageTable"
                    >
                    <v-icon>mdi-content-save</v-icon>
                    </v-btn>

                      </div>
                    </v-col>
                  </v-col>
                     
               <v-col   class="box"  cols="12" md="12" xl="12"  style="margin-top: 1rem;">
                      <div  style="padding: 16px;" >
                       
                        <div  style="padding-right: .8rem; display: flex; justify-content: flex-end;">
                          
                          <v-btn
                            class="btn-add"
                            style="margin-right: .5rem;"
                            @click="medicationDialog = true"
                          >
                          Agregar Vacunas/Medicamentos
                          </v-btn>
                        
                        </div>
                        <DxDataGrid 
                        :scrolling="{useNative: true}"
                         style="margin-top: 2.5rem;"
                          id="gridContainer"
                          ref="gridContainer"
                          @editing-start="onEditingStart"
                          :data-source="details"
                          :allow-column-reordering="true"
                          :show-borders="true"
                          :column-auto-width="true"
                          key-expr="ID_VPMP"
                          @row-updated="UpdateRow"
                          >
                         <!--  @row-updated="UpdateParamsRow"
                          @row-removed="DeleteParamsRow"
                          @row-inserted="saveParams" -->
                          <DxPaging :enabled="false" />
                          <DxEditing
                            :allow-updating="true"
                            :allow-deleting="true"
                            :confirmDelete = false
                            :select-text-on-edit-start="true"
                            start-edit-action="click"
                            mode="cell"
                            :use-icons="true"
                            />
                            <DxKeyboardNavigation
                            :edit-on-key-press="true"
                            enter-key-action="moveFocus"
                            enter-key-direction="column"
                          />
                          <DxEditing
                    :allow-updating="true"
                    :allow-deleting="true"
                    :confirmDelete="false"
                    :select-text-on-edit-start="true"
                    start-edit-action="click"
                    mode="cell"
                    :use-icons="true"
                  />
                  <DxColumn
                    :width="columnWidth"
                    :allow-editing="true"
                    caption="Orden"
                    data-field="Order"
                  />
                  <!--DxColumn
                  :width="30"
                    :allow-editing="false"
                    data-type="boolean"
                    data-field="checkbox"
                    header-cell-template="headerId"
                  ></DxColumn-->
                  <DxKeyboardNavigation
                    :edit-on-key-press="true"
                    enter-key-action="moveFocus"
                    enter-key-direction="column"
                  />
                  <DxColumn
                    data-field="Medicine"
                    :allow-editing="false"
                    header-cell-template="headerVaccines"
                    :width="columnWidth"
                  ></DxColumn>
                  <DxColumn
                    :width="columnWidth"
                    data-field="PeriodTime"
                    header-cell-template="headerPeriodTime"
                    data-type="number"
                  ></DxColumn>
                  <DxColumn
                    :width="columnWidth"
                    cell-template="periodUnit-selection"
                    header-cell-template="headerUnitPeriod"
                  >
                  </DxColumn>
                  <DxColumn
                    data-field="NextTime"
                    caption="Proxima vez"
                    data-type="date"
                    :allow-editing="false"
                    header-cell-template="headernextTime"
                  ></DxColumn>
                  <DxColumn
                    header-cell-template="headerItem"
                    cell-template="ItemType"
                    :allow-editing="false"
                  />

                  <template #periodUnit-selection="{ data }">
                    <span class="scroll-fixing-purchase">
                      <select
                        class="form-control custom-select"
                        v-model="data.data.PeriodUnit"
                        @change="handleMed"
                      >
                        <option v-for="item in items" :value="item.Name" :key="item.ID">
                          {{ item.NameESP }}
                        </option>
                      </select>
                    </span>
                  </template>

                  <template #headerPeriodTime>
                    <div>
                      Período
                    </div>
                  </template>
                  <template #headerUnitPeriod>
                    <div>
                      Unidad de <br />
                      tiempo
                    </div>
                  </template>
                  <template #headerItem>
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div style="margin-top: 0.5rem">Tipo</div>
                    </div>
                  </template>
                  <template #ItemType="{ data }">
                    <div>
                      <label v-if="data.data.ItemType == 'VAC'">Vacuna</label>
                      <label v-if="data.data.ItemType == 'MED'">Medicamento</label>
                    </div>
                  </template>
                  <template #headernextTime>
                    <div>
                      Proxima <br />
                      Vez
                    </div>
                  </template>
                  <template #headerId>
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div style="margin-top: 0.5rem">ID</div>
                    </div>
                  </template>
                  <template #headerVaccines>
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div style="margin-top: 0.5rem">Medicamentos / Vacunas</div>
                    </div>
                  </template>
                        </DxDataGrid>
                      </div>
                    </v-col>

         
            </v-row>

</div>


          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
    <v-dialog v-model="loading" max-width="290">
      <div>
    <transition name="fade">
      <div  class="loading-overlay">
        <div class="loading-dialog">
          <div class="loading-spinner"></div>
          <p class="loading-text">{{ messageload }}</p>
          <p class="loading-text">por favor no cierre el programa</p>
        </div>
      </div>
    </transition>
  </div>

    </v-dialog>
    


        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        ></alerts>

        <v-dialog v-model="medicationDialog" scrollable v-if="plan" width="80%" :key="reloadMed">
          <VaccineListPopUp
            :PlanId="plan.ID_Medication_Plan"
           
            @closeMedic="closeMedicPopUp($event)"
            :medicationDialog="medicationDialog"
            :GetALL="true"
            :template="true"
            :key="reloadMed"
          ></VaccineListPopUp>
        </v-dialog>




  </v-expansion-panel>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  //DxLookup,
  DxKeyboardNavigation,

} from "devextreme-vue/data-grid";

import Alerts from "../../../../components/Alerts.vue";
import VaccineListPopUp from "@/components/VeterinaryMedication/MedicationList.vue";


export default {
  name: "MedicationVetTemplate",
  components: {
    VaccineListPopUp,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,

    Alerts,
    DxKeyboardNavigation,
  },
  props: ["win"],
  data() {
    return {
      row: null,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      required: [(v) => !!v || "El campo es requerido" ],
      valid: false,
      tab: null,
      templatesList: [],
      form: {},
      details: [],
      ID_Test: null,
      showTemplates: false,
      laboratoryName: null,
      templateToDelete: null,
      templateToCopy: null,
      deleteDialog: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      ID_LaboratoryTest: "",
      id_user_login: null,
      showAddbutton: false,
      medicationDialog: false,
      plan: {},
      reloadp: 0 ,
      search: {},
      newTable: [],
      detailsparams: null,
      loadCreated: false,
      loading: false,
      reloadMed: 0,
      messageload: "Guardando...",
     
      items: [
      {
        ID: "1",
        Name: "Day",
        NameESP: "Día",
      },
      {
        ID: "2",
        Name: "Week",
        NameESP: "Semana",
      },
      {
        ID: "3",
        Name: "Month",
        NameESP: "Mes",
      },
      {
        ID: "4",
        Name: "Year",
        NameESP: "Año",
      },
    ],
      
      reloadNewnames: false,
    };
  },
 
  mounted() {
    this.getTemplateDefault();
  },
  computed: {
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
  },
  methods: {
    getTemplateDefault(){
      this.$API.veterinarypatients.findmedicationstemplate().then((response) => {
        if(response.length > 0){
          this.details = response.map((el) => {
            return {
              ...el,
              methods: "insert",
              ID_VPMP: Math.random().toString(36).substr(2, 9),
            };
          });
          this.setNextDay();
        }
        else{
          this.details = [];
        }
      
      })
      .catch((err) =>{
        console.log(err)
      })
    },
    async setNextDay() {
      this.details = await this.manageNextDate(this.details);
    },
    saveTemplate(){
      this.loading = true;
      this.$API.veterinarypatients.createMedicationsTemplate(this.details).then(() => {
        this.showAlert(
          "success",
          "Ingreso correcto",
          "Plantilla ingresada correctamente",
        );
        this.loading = false;
      })
      .catch(() => {
        this.showAlert(
          "danger",
          "Ocurrio un problema",
          "Ha ocurrido un error al ingresar la plantilla"
        );
        this.loading = false;
      });
    },
    async closeMedicPopUp(e) {

      e.map((el) => {
        if (el.checkbox == true)
        if (this.details.length > 0) {
          if (this.details.indexOf(el) == -1)
            this.details.push({
              ...el,
              methods: "insert",
              ID_VPMP: Math.random().toString(36).substr(2, 9),
            });
        } else {
          this.details.push({
            ...el,
            methods: "insert",
            ID_VPMP: Math.random().toString(36).substr(2, 9),
          });
        }
      });
      this.details = await this.manageNextDate(this.details);
      this.medicationDialog = false;
      this.reloadMed++;
    },
    filterHandler(response){
      this.templatesList = response
    },
    async manageNextDate(e) {
      for (let x = 0; x < e.length; x++) {
        e[x].NextTime = await this.moment()
          .add(e[x].PeriodTime, e[x].PeriodUnit)
          .format("DD/MM/YYYY");
      }
      return Promise.all(e);
    },
    clean(){
      this.form = {};
      this.details = [];
      this.getTemplateDefault();
      this.newTable = [];
      this.newTable = [];
    },
   
    getOrdernumber(){
            let order = 0;
            this.details.forEach(element => {
                if(element.ParameterOrder > order){
                    order = element.ParameterOrder;
                }
            });
            let x = parseInt(order);
            return x ? x + 1 : 1;
    },
    changueHandler(data){
      if(data.Created) return

      this.newTable.push({ data: {...data,id_user_update: this.id_user_login, id: data.ID_TestParam ,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "update"})

    },

    onEditingStart(e) {
      console.log(e)
    },
    handleRowUpdating(e){
      
      console.log(e)
    },
    handleRowDeleting(e){
      console.log(e)
    
    },
    handleRowInserting(e){

      //const {ParameterExpected,ParameterName,ParameterOrder,ParameterType,ParameterUnit,ParameterValue} = e.data
      this.newTable.push({ data: { ...e.data,id_user_insert: this.id_user_login, id: this.ID_LaboratoryTest,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "insert"}) //add values extra


    },

    getInserted(){
      let inserted = [];
      this.details.forEach(element => {
        if(element.Created){
          inserted.push({data: {...element,id_user_insert: this.id_user_login, id: this.ID_LaboratoryTest,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "insert"});
        }
      });
      if(inserted.length > 0) this.newTable.push(...inserted);
    },
    async handleMed() {
      this.details = await this.manageNextDate(this.details);
    },
    updateTable() {
      this.loading = true;
      //get Inserted values
      this.details.forEach(element => {
        if(element.Created){
          this.newTable.push({data: {...element,id_user_insert: this.id_user_login, id: this.ID_LaboratoryTest,ID_Business:this.search.business, ID_Branch: this.search.branch} , methods: "insert"});
        }
      });

       if(this.newTable.length == 0) {
        this.showAlert("success","No hay cambios","No se han realizado cambios en la tabla")
        this.loading = false;
        return
        }

        this.$API.laboratory.updatealltable(this.newTable).then(() => {
          this.loadCreated = false;
          this.newTable = [];
          let data ;

          this.templatesList.forEach(element => {

            if(element.ID_LaboratoryTest == this.ID_LaboratoryTest){

              data = element;
            }
          });
          if(data) this.showLabDetails(data);
          //this.clean();
          //this.showLabDetails(data);

          this.showAlert(
              "success",
              "Ingreso correcto",
              "Parametros ingresados correctamente",

            );
           this.loading = false;


        })
        .catch(() => {

          this.showAlert(
            "danger",
            "Ocurrio un problema",
            "Ha ocurrido un error al ingresar los parametros"
          );

          this.loading = false;
        });
      //dont forget reload tabl


    },
    async UpdateRow(e) {
      this.details = await this.manageNextDate(this.details);
      if (this.rightTableUniqueParameter == "ID_VPMP") {
        if (e.data.methods != "insert") {
          this.newTable.push({ data: { ...e.data }, methods: "update" });
        }
      }
      this.orderParams(this.details);
    },
    orderParams(array) {
      array.sort(function (a, b) {
        if (
          (a["Order"] === "" || isNaN(parseInt(a["Order"]))) &&
          b["Order"] !== "" &&
          !isNaN(parseInt(b["Order"]))
        ) {
          return 1;
        } else if (
          a["Order"] !== "" &&
          !isNaN(parseInt(a["Order"])) &&
          (b["Order"] === "" || isNaN(parseInt(b["Order"])))
        ) {
          return -1;
        } else if (
          (a["Order"] === "" || isNaN(parseInt(a["Order"]))) &&
          (b["Order"] === "" || isNaN(parseInt(b["Order"])))
        ) {
          return 0;
        } else if (parseInt(a["Order"]) === parseInt(b["Order"])) {
          return 0;
        } else {
          return parseInt(a["Order"]) < parseInt(b["Order"]) ? -1 : 1;
        }
      });
      return array;
    },
    /*Function for Alerts*/
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    closeAlert() {
      this.alert.show = false;
      

    },
    acceptAlert() {
      this.alert.show = false;
    },
   

  },
};
</script>

<style scoped>
.box{
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;

    border-radius: 15px;
   /*  margin: 0.5rem; */

}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}

.loading-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-top: 3px solid #3490dc;
  border-right: 3px solid #3490dc;
  border-bottom: 3px solid #3490dc;
  border-left: 3px solid #eee;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #3490dc;
}
.btnblue {
    background-color: #1976d2 !important;
    color: white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}
.tabs {

padding: 10px;
background-color: rgba(60, 60, 60, 1);
color: white;
}

</style>
